div.container {
  padding: 0;
  max-width: 100%;
}

.leaflet-container {
  width: 100%;
  height: 100vh;
}

h2 {
  font-weight: bold;
  text-align: center;
}

.leaflet-control .btn {
  color: white;
  display: block;
  margin-bottom: 4px;
}